import { Box, BoxProps, withDefaults } from 'theme/components';

export default withDefaults<BoxProps>({
  bg: 'white',
  borderRadius: 'large',
  boxShadow: 'layout',
  display: 'flex',
  flexDirection: 'column',
  mt: 'large',
  p: 'large',
})(Box);
