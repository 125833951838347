import { Action, Dispatch } from 'redux';
import { DataSource, fetchDataSource } from '../../data-layer/dataSource';

export const SET_DATA_SOURCE_TYPE = 'SET_DATA_SOURCE';

export interface SetDataSourceAction extends Action<typeof SET_DATA_SOURCE_TYPE> {
  dataSource: DataSource;
}

export const getDataSource = () => async (dispatch: Dispatch) => {
  const dataSource = await fetchDataSource();
  return dispatch<SetDataSourceAction>({
    dataSource,
    type: SET_DATA_SOURCE_TYPE,
  });
};
