import { styled, themeGet } from '@qwealth/qcore';

import { Link, LinkProps } from './Link';
import withDefaults from './withDefaults';

export interface TabProps extends LinkProps {
  id: string;
  isSelected?: boolean;
  role?: string;
}

const getActiveStyles = (props: TabProps) => {
  if (!props.isSelected) return '';

  const primaryColor = themeGet('colors.primary')(props);
  return `
    border-color: ${primaryColor};
    color: ${primaryColor} !important;
    font-weight: ${themeGet('fontWeights.bold')(props)};
  `;
};

const getPaddingStyle = (props: TabProps) => {
  const spacing = themeGet('space.large')(props);
  const pb = Number(spacing.replace('rem', '')) - 0.214;
  return `padding: ${spacing} ${spacing} ${pb}rem ${spacing};`;
};

const StyledTab = styled(Link)`
  border-bottom: 3px solid transparent;
  display: flex;
  justify-content: center;
  transition: border-color 0.3s;
  ${getPaddingStyle};
  ${getActiveStyles};

  &:hover:not([disabled]) {
    border-color: ${themeGet('colors.primary')};
    text-decoration: none;
  }
`;

export const Tab = withDefaults<TabProps, HTMLLinkElement>({
  role: 'tab',
  // @ts-ignore
})(StyledTab);
