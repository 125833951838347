import { styled } from '@qwealth/qcore';
import { system } from 'styled-system';

import { Box, BoxProps, withDefaults } from 'theme/components';
import { StyledComponent } from 'theme/types';
import { Breakpoint } from 'theme/factory/breakpoints';

import Body from './Body';
import Breadcrumbs from './Breadcrumbs';
import Header from './Header';
import Nav from './Nav';

interface PageLevelComponents {
  Breadcrumbs: typeof Breadcrumbs;
  Header: typeof Header;
  Nav: typeof Nav;
  Body: typeof Body;
}

interface AppPageProps extends BoxProps {
  maxWidth?: Breakpoint;
}

interface AppPageComponent extends StyledComponent<AppPageProps>, PageLevelComponents {}

const centerPage = system({
  maxWidth: {
    property: 'maxWidth',
    scale: 'breakpoints',
  },
});

const StyledAppPage = styled(Box)(centerPage);

// Main
const AppPage = withDefaults<BoxProps>({
  display: 'grid',
  gridTemplateRows: 'auto',
  maxWidth: 'desktop',
  mb: 'large',
  mx: 'auto',
  px: 'large',
  // @ts-ignore
})(StyledAppPage) as AppPageComponent;

AppPage.Breadcrumbs = Breadcrumbs;
AppPage.Header = Header;
AppPage.Nav = Nav;
AppPage.Body = Body;

export default AppPage;
