import React, { Suspense, useEffect, useState } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import { SilentRequest } from '@azure/msal-browser';
import * as Sentry from '@sentry/react';
import { AppLayout, AppPage, getAccessAndIdToken, Loader, useAcquireToken } from '@qwealth/qcore';
import { getPersons, QContext } from '@qwealth/qdata';

import { getHousehold } from 'redux/actions/householdAction';
import Intro from 'components/Intro';

import useDispatchOnMount from './hooks/useDispatchOnMount';
import useHouseholdIdPath from './hooks/useHouseholdIdPath';
import { getDataSource } from './redux/actions/dataSourceAction';

import AppMain from './AppMain';
import Breadcrumbs from './components/Breadcrumbs';
import Header from './components/Header';
import NotFoundPage from './components/NotFoundPage';
import TopBar from './components/TopBar';

const App = () => {
  const [initialized, setInitialized] = useState(false);
  const { idToken, account, isInProgress } = useAcquireToken();
  useEffect(() => {
    if (idToken && account) {
      Sentry.configureScope(scope => scope.setUser({ email: account.username }));

      QContext.setIdToken(idToken);

      // refresh the token every 10 minutes
      setInterval(() => {
        getAccessAndIdToken({ forceRefresh: true } as SilentRequest).then(auth => {
          if (auth) {
            QContext.setIdToken(auth.idToken);
          }
        });
      }, 10 * 60 * 1000);
      setInitialized(true);
    }
  }, [idToken, account]);

  // fetch global data source
  useDispatchOnMount(getDataSource, idToken);

  const householdId = useHouseholdIdPath();
  useDispatchOnMount(getHousehold, initialized ? householdId : undefined);
  useDispatchOnMount(getPersons, initialized ? householdId : undefined);

  const isIntro = useRouteMatch({ path: '/', exact: true });

  if (isInProgress || !idToken) {
    return (
      <AppLayout alignItems="center">
        <Loader />
      </AppLayout>
    );
  }

  return (
    <>
      {!isIntro && <TopBar />}
      <AppPage style={{ maxWidth: '70%' }}>
        <AuthenticatedTemplate>
          {!isIntro && (
            <>
              <AppPage.Breadcrumbs>
                <Breadcrumbs />
              </AppPage.Breadcrumbs>
              <Header />
            </>
          )}
          <Suspense fallback={<div>Loading...</div>}>
            <Switch>
              <Route component={Intro} path="/" exact />
              <Route component={AppMain} path="/:household" />
              <Route component={NotFoundPage} path="*" />
            </Switch>
          </Suspense>
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          <Intro />
        </UnauthenticatedTemplate>
      </AppPage>
    </>
  );
};

export default App;
