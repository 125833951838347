import React from 'react';
import { BsFillBarChartFill, BsChevronRight } from 'react-icons/bs';
import { useSelector } from 'react-redux';

import { Box } from 'theme/components';
import { Household } from '../data-layer/household';
import { selectCurrentHousehold } from '../redux/selectors/householdSelectors';

export default () => {
  const { name } = useSelector(selectCurrentHousehold) as Household;

  return (
    <Box alignItems="center" display="flex" mt="default">
      <Box mr="default">
        <BsFillBarChartFill />
      </Box>
      <BsChevronRight />
      <Box mx="default">Household View</Box>
      <BsChevronRight />
      <Box ml="default">{name}</Box>
    </Box>
  );
};
