import { Action, Dispatch } from 'redux';

import { fetchHousehold, Household } from 'data-layer/household';

export const SET_HOUSEHOLD_TYPE = 'SET_HOUSEHOLD';

export interface SetHouseholdAction extends Action<typeof SET_HOUSEHOLD_TYPE> {
  household: Household;
}

export const getHousehold = (householdId: string) => async (dispatch: Dispatch) => {
  const household = await fetchHousehold(householdId);
  dispatch<SetHouseholdAction>({
    household,
    type: SET_HOUSEHOLD_TYPE,
  });
};
