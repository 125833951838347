import React, { Children, cloneElement } from 'react';
import { Box, BoxProps } from 'theme/components';

import { Tab } from './Tab';

export interface TabsProps extends Omit<BoxProps, 'children'> {
  children: Array<React.ReactElement<typeof Tab>>;
  onSelect: (id: string) => void;
  selectedTab: string;
}

export const Tabs: React.FC<TabsProps> = ({ children, onSelect, selectedTab, ...props }) => {
  const createOnClickHandler = (id: string) => () => {
    onSelect(id);
  };

  return (
    // @ts-ignore
    <Box as="nav" display="flex" justifyContent="center" role="tablist" {...props}>
      {Children.map(children, child => {
        // @ts-ignore
        const { id } = child.props;
        // @ts-ignore
        return cloneElement(child, {
          // @ts-ignore
          'aria-selected': selectedTab === id,
          isSelected: selectedTab === id,
          onClick: createOnClickHandler(id),
          role: 'tab',
        });
      })}
    </Box>
  );
};
