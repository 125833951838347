import React from 'react';
import { StyledComponent } from 'theme/types';

export interface WithDefaults {
  <Props, HTMLElement = HTMLDivElement>(defaultProps: Partial<Props>): (
    component: React.FC<Props> | React.ComponentType<Props> | StyledComponent<Props, HTMLElement>,
  ) => StyledComponent<Props, HTMLElement> | React.FC<Props>;
}

// @ts-ignore
const withDefaults: WithDefaults = defaultProps => Component => props => (
  <Component {...defaultProps} {...props} />
);

export default withDefaults;
