import { styled, themeGet } from '@qwealth/qcore';
import { buttonStyle, ButtonStyleProps, compose } from 'styled-system';
import { lighten } from 'polished';

import { Theme, StyledComponent } from 'theme/types';
import { buttonSizes, ButtonSizeProps } from 'theme/variants';

import { Box, BoxProps } from './Box';
import withDefaults from './withDefaults';

export interface ButtonProps
  extends Omit<BoxProps, 'size'>,
    ButtonSizeProps,
    ButtonStyleProps<Theme> {
  type?: string;
}

const colorOverride = (props: ButtonProps) => {
  if (!props.color) return '';

  const color = themeGet(`colors.${props.color}`)(props);
  const white = themeGet('colors.white')(props);

  if (props.variant === 'default') {
    return `
      background-color: ${color};
      color: ${white};

      &:before {
        background-color: ${lighten(0.1, color)};
      }
    `;
  }

  return `
    border-color: ${color};
    color: ${color};

    &:before {
      background-color: ${color};
    }

    &:hover:not([disabled]) {
      color: ${white};
    }
  `;
};

// @ts-ignore
const StyledButton = styled(Box)`
  ${compose(buttonStyle, buttonSizes)}
  ${colorOverride}
`;

export const Button = withDefaults<ButtonProps, HTMLButtonElement>({
  as: 'button',
  color: 'primary',
  size: 'default',
  type: 'button',
  variant: 'default',
})(StyledButton as StyledComponent<ButtonProps, HTMLButtonElement>);
