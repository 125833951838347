import { variant } from 'styled-system';

import { ButtonSize } from './types';

export interface ButtonSizeProps {
  size?: keyof typeof ButtonSize;
}

export const buttonSizes = variant({
  prop: 'size',
  scale: 'buttonSizes',
});
