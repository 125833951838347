import { styled } from '@qwealth/qcore';
import shouldForwardProp from '@styled-system/should-forward-prop';
import {
  border,
  BorderProps,
  color,
  ColorProps,
  compose,
  display,
  DisplayProps,
  flexbox,
  FlexboxProps,
  grid,
  GridProps,
  layout,
  LayoutProps,
  shadow,
  ShadowProps,
  space,
  SpaceProps,
  system,
  textStyle,
  TextStyleProps,
  typography,
  TypographyProps,
  ThemeValue,
  ResponsiveValue,
} from 'styled-system';

import { StyledComponent, Theme } from 'theme';

interface GridGapProps<ThemeType extends Partial<Theme>, TVal = ThemeValue<'space', ThemeType>> {
  columnGap?: ResponsiveValue<TVal, ThemeType>;
  gap?: ResponsiveValue<TVal, ThemeType>;
  rowGap?: ResponsiveValue<TVal, ThemeType>;
}

export interface BoxProps<T = Required<Theme>>
  extends BorderProps<T>,
    ColorProps<T>,
    DisplayProps<T>,
    FlexboxProps<T>,
    GridProps<T>,
    GridGapProps<T>,
    LayoutProps<T>,
    ShadowProps<T>,
    SpaceProps<T>,
    TextStyleProps<T>,
    TypographyProps<T> {
  as?: React.ReactType;
  children?: React.ReactNode;
}

const gridGap = system({
  columnGap: {
    property: 'columnGap',
    scale: 'space',
  },
  gap: {
    property: 'gap',
    scale: 'space',
  },
  rowGap: {
    property: 'rowGap',
    scale: 'space',
  },
});

export const Box = styled('div', { shouldForwardProp })(
  compose(
    border,
    color,
    display,
    flexbox,
    grid,
    gridGap,
    layout,
    shadow,
    space,
    textStyle,
    typography,
  ),
) as StyledComponent<BoxProps>;
