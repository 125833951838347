import { Box, BoxProps, withDefaults } from 'theme/components';

export default withDefaults<BoxProps>({
  bg: 'primary',
  borderTopLeftRadius: 'large',
  borderTopRightRadius: 'large',
  boxShadow: 'layout',
  mt: 'large',
  px: 'largest',
  py: 'large',
})(Box);
