import { Reducer } from 'redux';
import { reducerFactory } from '@qwealth/qdata';
import { DataSource } from '../../data-layer/dataSource';
import { SET_DATA_SOURCE_TYPE, SetDataSourceAction } from '../actions/dataSourceAction';

export interface DataSourceReduxState extends DataSource {}

const setDataSource: Reducer<DataSourceReduxState, SetDataSourceAction> = (
  state,
  { dataSource },
) => ({
  ...state,
  ...dataSource,
});

export default reducerFactory({
  [SET_DATA_SOURCE_TYPE]: setDataSource,
});
