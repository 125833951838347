import React from 'react';

import { Box } from 'theme/components';
import { AppPage } from 'theme/templates';

export default () => (
  <AppPage.Body alignItems="center" height="300px" justifyContent="center">
    <Box textStyle="sectionTitle">Page Not Found</Box>
  </AppPage.Body>
);
