import { styled, themeGet } from '@qwealth/qcore';
import { ButtonStyleProps } from 'styled-system';

import { Theme, StyledComponent } from 'theme/types';
import { Box, BoxProps } from './Box';
import withDefaults from './withDefaults';

export interface LinkProps extends BoxProps, ButtonStyleProps<Theme> {}

const StyledButton = styled(Box)`
  &[disabled] {
    color: ${themeGet('colors.gray.2')};
  }
  &:focus {
    box-shadow: 0px 0px 7px -2px ${themeGet('colors.primaryLight.0')};
    outline: 0;
  }
  &:hover:not([disabled]) {
    cursor: pointer;
    text-decoration: underline;
  }
`;

export const Link = withDefaults<LinkProps, HTMLLinkElement>({
  as: 'a',
  color: 'primary',
})(StyledButton as StyledComponent<LinkProps, HTMLLinkElement>);
