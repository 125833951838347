import { lensPath, set } from 'ramda';

export function mergeWithLocalStorage<T extends {}>(key: string, data: T): T {
  const storedData = localStorage.getItem(key);
  return storedData
    ? Object.entries(JSON.parse(storedData)).reduce(
        (acc, [pathKey, value]) => set(lensPath(JSON.parse(pathKey)), value, acc),
        data,
      )
    : data;
}

export function writeToLocalStorage(key: string, paths: Array<string | number>, data: any) {
  const existingData = localStorage.getItem(key);
  const stored = existingData ? JSON.parse(existingData) : {};
  const pathKey = JSON.stringify(paths);
  stored[pathKey] = data;
  localStorage.setItem(key, JSON.stringify(stored));
}
