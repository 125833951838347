import { combineReducers } from 'redux';
import {
  accountsReducer,
  incomeSummaryReducer,
  monthOverMonthReducer,
  personsReducer,
} from '@qwealth/qdata';
import dataSource from './dataSource';
import household from './household';

export default combineReducers({
  accounts: accountsReducer,
  dataSource,
  household,
  incomeSummary: incomeSummaryReducer,
  monthOverMonth: monthOverMonthReducer,
  persons: personsReducer,
});
