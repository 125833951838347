import React from 'react';
import { render } from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import axios from 'axios';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { MsalProvider } from '@azure/msal-react';
import { defaultTheme, ThemeProvider } from '@qwealth/qcore';
import { QContext, QWealthAuthorizationInterceptor } from '@qwealth/qdata';

import { msalInstance } from 'data-layer/msal';

import App from './App';
import store from './redux/store';

import './theme.scss';
import './App.scss';
import './Autosuggest.scss';

const { REACT_APP_VERSION, REACT_APP_ENV } = process.env;

Sentry.init({
  dsn: 'https://0f2704f6c7bd45309d3d2d880cd61c4b@o1168654.ingest.sentry.io/6418865',
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
  environment: REACT_APP_ENV,
});

const theme = {
  ...defaultTheme,
  colors: {
    ...defaultTheme.colors,
    primary: defaultTheme.colors.green,
    primaryTransparent: defaultTheme.colors.greenTransparent,
  },
};

// eslint-disable-next-line no-console
console.log(`Current app version is: ${REACT_APP_VERSION}`);

QContext.setErrorHandler(console.error);

axios.interceptors.request.use(QWealthAuthorizationInterceptor);

render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <MsalProvider instance={msalInstance}>
        <Router>
          <Provider store={store}>
            <App />
          </Provider>
        </Router>
      </MsalProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);
